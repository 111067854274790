<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-5 d-flex flex-column">
        <v-card flat outlined class="flex d-flex flex-column">
          <v-card-subtitle class="pb-0 d-flex"
            ><span>Prospect</span
            ><span class="ml-auto"
              >Advisor: {{ detailItem.advisor.lastName }},
              {{ detailItem.advisor.firstName }}</span
            ></v-card-subtitle
          >
          <div
            v-if="loading"
            class="h-100 d-flex align-center justify-center mb-8"
          >
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </div>

          <v-row v-if="!loading && reportInput" class="align-center">
            <v-col class="col-auto">
              <div style="width: 100px" class="pl-4">
                <GenderAgeAvatarStandalone
                  :age="reportInput.financial_profile_info.current_age"
                  :gender="reportInput.gender"
                />
                <div class="mt-1 text-center">
                  <small
                    >Age:{{
                      reportInput.financial_profile_info.current_age
                    }}</small
                  >
                </div>
              </div>
            </v-col>
            <v-col class="pl-0">
              <v-card-title class="pb-2 pt-0">
                {{ detailItem.lastName }}, {{ detailItem.firstName }}
              </v-card-title>
              <v-card-text class="flex">
                <v-row>
                  <v-col>
                    <span v-if="detailItem.phone"
                      >{{ detailItem.phone }}<br
                    /></span>

                    <span v-if="detailItem.email"
                      >{{ detailItem.email }}<br
                    /></span>
                  </v-col>

                  <v-col v-if="detailItem.address">
                    <span v-if="detailItem.address.streetAddress1"
                      >{{ detailItem.address.streetAddress1 }}<br
                    /></span>

                    <span v-if="detailItem.address.streetAddress2"
                      >{{ detailItem.address.streetAddress2 }}<br
                    /></span>

                    {{
                      detailItem.address.city ? detailItem.address.city : ""
                    }},
                    {{
                      detailItem.address.state ? detailItem.address.state : ""
                    }}
                    {{
                      detailItem.address.postalCode
                        ? detailItem.address.postalCode
                        : ""
                    }}
                  </v-col></v-row
                >
              </v-card-text>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn
              outlined
              color="primary"
              target="_blank"
              class="mr-2"
              :href="getProspectUrl(detailItem.id, 'lead-sheet')"
              >Lead Sheet</v-btn
            >
            <v-btn
              outlined
              color="primary"
              target="_blank"
              :href="getProspectUrl(detailItem.id, 'your-score-report')"
              >Score Report</v-btn
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-auto" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-archive-outline</v-icon>
                </v-btn>
              </template>
              <span>Archive</span>
            </v-tooltip>
          </v-card-actions></v-card
        ></v-col
      >
      <v-col class="d-flex flex-column">
        <v-card class="d-flex flex-column flex" elevation="12">
          <div v-if="loading" class="h-100 d-flex align-center justify-center">
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </div>

          <v-card-text
            v-if="!loading && reportInput"
            class="flex justify-center align-center"
          >
            <div style="max-width: 350px" class="mx-auto">
              <ReportCompareAnimationSideBySide
                :prospectScore="reportInput.score"
                :averageScore="reportInput.average_score_for_age"
                colorScheme="light"
              />
              <div class="text-caption text-center">
                Survey Completed:
                {{
                  new Date(detailItem.createdAt).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column">
        <v-card flat outlined class="d-flex flex-column flex">
          <v-card-subtitle>Metrics</v-card-subtitle>
          <div v-if="loading" class="d-flex align-center justify-center">
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </div>
          <v-card-text v-if="!loading && reportInput">
            <div class="d-flex flex-column justify-center">
              <div class="mb-4">
                <v-icon color="success">mdi-check-circle</v-icon
                ><span class="ml-4 font-weight-bold">Qualified</span>
              </div>
              <div class="mb-4">
                <v-chip dark label outlined color="primary">
                  <span class="text-h3">
                    {{ reportInput.product_recommendations.length }}
                  </span></v-chip
                ><span class="ml-4 font-weight-bold"
                  >Product Recommendation</span
                >
              </div>
              <v-divider class="my-4"> </v-divider>
              <div class="text-center">
                <div class="text-h6 font-weight-bold success--text">
                  {{
                    currencyFormatter(reportInput.retirement_savings_midpoint)
                  }}
                </div>
                <div class="ml-4 font-weight-bold">
                  Potential Asset Management
                </div>
              </div>
            </div>
          </v-card-text></v-card
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-card-text>
                <p class="text-body-1 font-weight-bold mb-8">
                  Risk Score Survey Answers
                </p>

                <ProspectSurveyAnswersGridVue
                  v-if="detailItem.scoreSurveyAnswers"
                  :answers="JSON.parse(detailItem.scoreSurveyAnswers)"
                />
                <v-card-title v-else>No Survey Answers Found</v-card-title>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col></v-row
    >
  </div>
</template>

<script>
import GenderAgeAvatarStandalone from "@/components/GenderAgeAvatarStandalone";
import ProspectSurveyAnswersGridVue from "@/components/ProspectSurveyAnswersGrid";
import ReportCompareAnimationSideBySide from "@/components/ReportCompareAnimationSideBySide";

export default {
  components: {
    GenderAgeAvatarStandalone,
    ProspectSurveyAnswersGridVue,
    ReportCompareAnimationSideBySide,
  },
  props: {
    detailItem: {
      type: Object,
    },
  },
  data() {
    return {
      report: null,
      reportInput: null,
      loading: false,
    };
  },
  computed: {
    getProspectScore() {
      let score = null;
      if (this.detailItem.scoreSurveyResults) {
        score = JSON.parse(this.detailItem.scoreSurveyResults).final_score;
      }
      return score;
    },
  },
  mounted() {
    this.fetchReport(this.detailItem.id);
  },
  methods: {
    getProspectUrl(id, path) {
      return window.location.origin + "/" + path + "/" + id;
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value);
    },
    fetchReport(prospectId) {
      // Fetch score by submitting scoring params
      this.loading = true;
      console.log("PROS ID", prospectId);
      console.log("ENV", process.env.VUE_APP_SCORE_API);
      fetch(process.env.VUE_APP_SCORE_API + "score_report_v3", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prospectId),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong fetching report", response);
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("data report", data);
            if (!data.input || !data.input.product_recommendations) {
              this.message =
                "Sorry, we were unable to locate your report. Please refresh to try again or contact your advisor for assistance.";
            } else {
              this.report = data.report;
              this.reportInput = data.input;
            }
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (!this.report) {
            this.message = error.message;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
